// Optics are weird, made smaller and corrected placement due to rotate
.ticket-thumbnail--milestone {
	//need both min and max heights so that overall ticket-edit-container__header-thumbnail class doesn't override the size
	min-width: 26px;
	min-height: 26px;
	max-width: 26px;
	max-height: 26px;
	-webkit-transform: rotate(45deg) translate(7px, 0);
	transform: rotate(45deg) translate(7px, 0);
	margin-right: var(--space-8);

	// Need to unrotate completed icon
	i {
		-webkit-transform: rotate(-45deg) translate(3px, -2px);
		transform: rotate(-45deg) translate(3px, -2px);
	}
}

.ticket-edit-container__header-thumbnail {
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ticket-thumbnail--constraint {
	border-radius: 50%;
}
