/* ----------------------------------------------
 * Generated by Animista on 2019-11-20 8:25:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation blink-2
 * ----------------------------------------
 */
@keyframes blink-2 {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

.blink-2 {
	animation: blink-2 0.9s both;
}
