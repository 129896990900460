.harness-flex {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.harness-component-small-width {
	width: 400px;
}

harness {
	margin: 12px;
	display: block;
}

//move to a loading-blocker file
.square {
	width: 300px;
	height: 300px;
}
