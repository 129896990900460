/*Panels and side panels default styling*/
.panel-old {
	position: absolute;
	background: #484848;
	//border:1px solid #999999;
	//box-shadow: 5px 5px 12px rgba(0,0,0,0.6);

	h3 {
		padding: 10px 0 10px 16px;
		margin: 0;
		text-align: left;
		color: #aaa;
		font-size: 1.2em;
		font-weight: 300;
		float: left;
	}
}

.panel-new {
	position: absolute;
	background: #484848;
	//border:1px solid #999999;
	//box-shadow: 5px 5px 12px rgba(0,0,0,0.6);

	h1,
	h2,
	h3,
	h4,
	h5 {
		color: #ddd;
	}
}

select {
	color: #666;
	font-size: 16px;
}
