// remove the spinner in number input fields
input.remove-spinner::-webkit-outer-spin-button,
input.remove-spinner::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

// hack to hide spectrum color picker toggle on new summary page role/location dialog
::ng-deep .color-picker-toggle-hidden {
	// offset the margin due to hidden replacer
	.color-picker-less-margin {
		margin-left: -30px; /* stylelint-disable-line */
	}

	.sp-replacer {
		visibility: hidden;
	}
}

::ng-deep .search-input-material {
	padding-right: var(--space-8);
	padding-left: var(--space-40);
	border-color: var(--light-grey);
	border-style: solid;
	border-width: 1px;
}

select:disabled,
textarea:disabled,
input:disabled {
	// needed to ensure proper disabled behavior on Firefox
	opacity: var(--disabled-opacity);
	// needed so mobile Safari does not overly fade disabled fields
	-webkit-text-fill-color: var(--black);
	-webkit-opacity: var(--disabled-opacity);
}

select:read-only,
textarea:read-only,
input:read-only {
	// Without this, "readonly" makes the background gray
	background-color: transparent;

	// Without this, "readonly" changes the color
	/* stylelint-disable */
	color: currentColor;
	/* stylelint-enable */
}

//"Remove old styling" override rules - delete when no longer needed

.remove-old-styling-input {
	border: unset;
	box-shadow: unset;

	&:focus {
		border: unset;
	}
}

.remove-old-styling-textarea {
	border: unset;
	box-shadow: unset;

	&:focus {
		border: unset;
	}
}
