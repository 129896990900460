/* ==========================================================================
   GLOBAL VARIABLES
   ========================================================================== */

$font-stack: 'Roboto', Arial, sans-serif;
$h1-font-size: 18px;
$h2-font-size: 16px;
$h3-font-size: 14px;

$primary-font-color: var(--grey60);

$touchplan-blue-accent: var(--touchplan-blue);
$touchplan-dark-blue-accent: var(--touchplan-blue-dark);

$touchplan-search-accent: var(--yellow-light);

$top-bar-color: var(--almost-black);

$primary-element-bg-color: var(--darker-grey);
$primary-menu-bg-hover-color: var(--almost-black);

$dependnt-panel-bg: var(--grey);

//Box Shadow Depth Levels
$box-shadow-level-1: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$box-shadow-level-2: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$box-shadow-level-3: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
$box-shadow-level-4: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
$box-shadow-level-5: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

$box-shadow-level-1-modified: 0 1px 3px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.12);

$kurtis-special-color: var(--touchplan-lime);

$error-red: var(--red);

//Light Theme
$light-background-color: var(--white);
$light-secondary-background-color: var(
	--lighter-white
); // Used for most of the grey area: sidebar, footer of ticet area
$light-blue-accent-background-color: var(--touchplan-blue-lighter);

//New Edit
$ticket-edit-input-border-color: var(
	--grey12
); // Used on all default state inputs, textareas and some buttons
$ticket-edit-input-border-enabled-color: var(--touchplan-blue); // Used for active form controls

//color used when disabling things, buttons, text, icons, etc
$disable-color: var(--grey38);
$disable-input: var(--grey2);
