//since ticket edit is broken up into several components, general ticket edit styling probably wants to
//be done mostly in one place and imported into each

.legacy-pin-margin-centering-fix {
	margin-top: var(--space-4);
}

.extra-space-at-bottom-of-edit-ui {
	height: 130px;
}

.ticket-edit-container__content.multi-edit {
	margin: var(--space-16);
}
