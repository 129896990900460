//reusable thing to have a vertically aligned div that works on ie10
// use @extend %valign-flex
%valign-flex {
	display: -ms-flexbox;
	-ms-flex-direction: column;
	-ms-flex-pack: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	span {
		//margin: 0 auto;
		overflow: hidden;
	}
}
%text-overflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
%groupclear {
	content: '';
	display: table;
	clear: both;
}

@mixin transition($stuff...) {
	-webkit-transition: $stuff;
	transition: $stuff;
}
@mixin box-shadow($stuff...) {
	-webit-box-shadow: $stuff;
	box-shadow: $stuff;
}

@mixin unbutton {
	background: none;
	box-shadow: none;
	outline: none;
	cursor: default;
	:hover {
		background: none;
	}
	:active {
		background: none;
	}
	:focus {
		background: none;
	}
}

//-----------------------------------------------------------------------------
// Flex api
// uses entirely "new style" syntax, occasionally mapping the old stuff for ie
// can add missing things later if necessary
//-----------------------------------------------------------------------------
@mixin display-flex() {
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}
@mixin flex-direction($direction) {
	-ms-flex-direction: $direction;
	-webkit-flex-direction: $direction;
	flex-direction: $direction;
}
@mixin flex-wrap($wrap) {
	-ms-flex-wrap: $wrap;
	-webkit-flex-wrap: $wrap;
	flex-wrap: $wrap;
}
@mixin justify-content($just) {
	$map: (
		center: center,
		flex-start: start,
		flex-end: end,
		space-around: distribute,
		space-between: justify,
	);
	-ms-flex-pack: map-get($map, $just);
	-webkit-justify-content: $just;
	justify-content: $just;
}
@mixin align-items($align) {
	$map: (
		center: center,
		flex-start: start,
		flex-end: end,
		baseline: baseline,
		stretch: stretch,
	);
	-ms-flex-align: map-get($map, $align);
	-webkit-align-items: $align;
	align-items: $align;
}

//short-hand for flex-grow, flex-shrink, flex-basis
// flex: flex-grow flex-shink flex-basis
@mixin flex($growNum, $shrinkNum, $basis) {
	-ms-flex: $growNum $shrinkNum $basis;
	-webkit-flex: $growNum $shrinkNum $basis;
	flex: $growNum $shrinkNum $basis;
}
