// Variables
//the amount of seperation for inputs/ buttons right beside each other
$input-seperation-v: 12px;
$input-seperation-v-header: 12px;
$input-seperation-h: 12px;

//All fonts types should be defined like this (or the above variable)
//Also will likely want to rename to something less terrible
@mixin light-font-biggest {
	font-weight: 500;
	font-size: $h2-font-size;
}
@mixin light-font-big {
	font-weight: 500;
	font-size: $h3-font-size;
}

input {
	// For Firefox
	min-width: 20px; //Crew and days

	// For Safari
	margin: 0; // Crew and days
}

.input-spacing-v {
	margin-bottom: $input-seperation-v;
}
.input-spacing-h {
	margin-right: $input-seperation-h;
}

//sets up all children of the thing this is applied to to have the default horizontal seperation
.space-children-h {
	& > * {
		margin-right: $input-seperation-h;
	}
	& > *:last-child {
		margin-right: 0;
	}
}

// Light theme styling
.light-container {
	background-color: $light-background-color;
	color: $ticket-edit-font-color;
	border: 1px solid $ticket-edit-input-border-color;
}

.light-container--border-radius {
	border-radius: 4px 4px 0 0;
}

.light-container__header {
	@include light-font-biggest;
	margin-bottom: $input-seperation-v;
}
.light-container__text-block {
	@include light-font-big;
	margin-bottom: $input-seperation-v;
}

.light-container__labels {
	text-transform: uppercase;
	font-size: 12px;
	padding-bottom: 2px;
}

// Used for ticket hamburger menu items, select all menu items
.light-container__menu-items {
	display: flex;
	height: 30px;
	padding: 0 5px;

	&:hover {
		background-color: $light-blue-accent-background-color;
	}
}

.light-container__menu-icon {
	width: 35px;
	text-align: center;
	align-self: center;
	color: $touchplan-blue-accent;
	font-size: 16px;
}

.light-container__menu-label {
	padding-left: 5px;
	font-size: 12px;
	align-self: center;
	white-space: nowrap;
}

// Use for Dropdown menus, pop ups, error messages boxes
.light-container-tray {
	background-color: $light-background-color;
	border-radius: 3px;
	font-size: 14px;
}

.light-container-tray__header {
	display: inline-block;
}

.light-container--border-blue {
	border: 1px solid $touchplan-blue-accent;
}

.light-container-tray--border-red {
	border: 1px solid $error-red;
}

.light-container-popup {
	box-shadow: 7px 8px 22px 2px #c7c7c7;
	padding: 15px;
}

//arbitrary because no direction
.light-container-popup--widths {
	// min-width: 240px;
	max-width: 500px;
}

//standard popup class (composed of more specific classes)
.light-container-popup-basic {
	@extend .light-container-tray;
	@extend .light-container-popup;
	@extend .light-container--border-blue;
	@extend .light-container-popup--widths;
}

.light-container-popup__content {
	overflow: auto;
	margin: 15px 0;
	border: 1px solid $ticket-edit-input-border-color;
}

.light-container-popup__content--height {
	height: 210px;
}

.light-container-popup__content--no-border {
	border: none;
}

.light-container-popup__content-item {
	display: grid;
	grid-template-columns: 130px 250px 50px;
	justify-content: space-between;
	padding: 20px 15px;
	border-top: 1px solid $ticket-edit-input-border-color;
}

.light-container-popup__content-item--procore {
	grid-template-columns: 120px 230px 80px;
}

.light-container-popup__content-item-data {
	color: #333333;
}

// Footer used when there is just two buttons
.light-container-popup__footer {
	display: flex;
	justify-content: flex-end;
}

// Footer used when there is three buttons. Delete button on the left
.light-container-popup__footer-three-buttons {
	justify-content: space-between;
}

.light-container-popup__footer-buttons-right .close-button {
	margin: 0 15px;
}

.light-toggle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: $h3-font-size;
}

// Error Message
.error-messages {
	width: 240px;
	position: fixed;
	top: 60px;
	left: 50hw;
}
