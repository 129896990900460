//READ ONLY OVERRIDES
//Should be made global at some point, might conflict so need to test
.read-only,
ticket-edit-wrapper textarea:disabled,
ticket-edit-wrapper input:disabled
// .ui-dropdown.ui-state-disabled .ui-dropdown-trigger,
// .ui-dropdown.ui-state-disabled .ui-dropdown-label
{
	border-color: $ticket-edit-input-border-color;
	background-color: $disable-input;
	color: $disable-color;

	&:hover {
		cursor: default;
	}
	// &:focus {
	// 	border-color: $ticket-edit-input-border-color;
	// }
}

// .ui-state-disabled, .ui-widget:disabled{
// 	opacity: 1;
// }

// Disabled Button styling - add disabled to button
.ticket-edit-footer button:disabled {
	opacity: 0.5;
	cursor: default;
}
// Change border for dropdowns when in disabled state
ticket-edit-wrapper .tp-dropdown.ui-state-disabled {
	border-color: $ticket-edit-input-border-color;
	background-color: $disable-input;
	color: $disable-color;
}
