//<fancy-checkbox>
//https://kyusuf.com/post/completely-css-custom-checkbox-radio-buttons-and-select-boxes
//this is the old one
$size: 32px;
@mixin checkSize($scale: 1) {
	width: floor($size * $scale);
	height: floor($size * $scale);
	&:after {
		left: floor(13px * $scale);
		top: floor(5px * $scale);
		width: floor(6px * $scale);
		height: floor(16px * $scale);
	}
}

.control {
	position: relative;
}
.control input {
	position: absolute;
	opacity: 0;
	margin: 0;
}

.control__indicator {
	position: absolute;
	top: 3px;
	left: 0;
	background: #e6e6e6;
	@include checkSize;
}

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
	background: #ccc;
}
.control input:checked ~ .control__indicator {
	background: #4fbcdb;
}
.control input:disabled ~ .control__indicator {
	background: #e6e6e6;
	opacity: 0.6;
	pointer-events: none;
	cursor: default;
}
.control__indicator:after {
	content: '';
	position: absolute;
	display: none;
}
.control input:checked ~ .control__indicator:after {
	display: block;
}
.control--checkbox .control__indicator:after {
	border: solid #fff;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}
.control--checkbox input:disabled ~ .control__indicator:after {
	border-color: #7b7b7b;
}

.control--checkbox {
	width: $size;
	height: $size;
	display: inline-block;
}

.small-checkbox .control--checkbox .control__indicator {
	@include checkSize(0.5);
}
.small-checkbox .control--checkbox {
	width: floor($size * 0.5);
	height: floor($size * 0.5);
}
.medium-checkbox .control--checkbox .control__indicator {
	@include checkSize(0.75);
}
.medium-checkbox .control--checkbox {
	width: floor($size * 0.75);
	height: floor($size * 0.75);
}

// The new one
.fancy-checkbox {
	input {
		display: none;
	}
}

.check-component {
	cursor: pointer;
	display: inline-block;
}

.sort-arrow {
	transition: transform 0.3s cubic-bezier(0.43, 0.09, 0.48, 1.52);

	i {
		font-size: 30px;
	}
}
.fancy-checkbox-on .sort-arrow {
	transform: rotate(180deg);
}

// // Okay so I'm using the same classes for the old checkbox sizes because it's easier than changing all the old checkbox classes to the new one...
// // this file is made to be a global file in main.scss and the sizes only works here... it doesn't work when i place this in scss/theme/_fancy-checkbox.scss (I'm not sure why this has to be made global - sorry). But basically dev... make this not global but apply it to the scss/theme/_fancy-checkbox.scss if possible.
