// Everything below is used in Plan Area only. DO NOT use with material.

// Commonly used color combos
@mixin touchplan-blue-combo {
	background-color: var(--touchplan-blue);
	color: var(--white);
}
@mixin almost-black-combo {
	background-color: var(--almost-black);
	color: var(--white);
}
@mixin darker-grey-combo {
	background-color: var(--darker-grey);
	color: var(--white);
}
@mixin dark-grey-combo {
	background-color: var(--dark-grey);
	color: var(--white);
}
@mixin grey-combo {
	background-color: var(--grey);
	color: var(--white);
}
@mixin lighter-white-combo {
	background-color: var(--lighter-white);
	color: var(--dark-grey);
}
@mixin white-combo {
	background-color: var(--white);
	color: var(--dark-grey);
}

// Type of boxes
@mixin simple-box {
	box-shadow: var(--box-shadow-8dp);
	padding: var(--space-16);
}

// Text Overflow
@mixin text-overflow {
	text-overflow: ellipsis;
	overflow: hidden;
	word-break: break-all;
}

// Base grid
@mixin base-grid {
	display: grid;
	grid-gap: var(--space-16);
}

// Base flex
@mixin base-flex {
	display: flex;
	align-items: center;
}
