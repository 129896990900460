.placeholder-search {
	display: flex;
	align-items: center;
	justify-content: unset;
	margin: 10px 5px 0;
	position: relative;
}
.placeholder-search-input {
	width: 100%;
	font-size: 13px;
	border-radius: 3px;
	padding: 8px 30px 8px 28px;
	box-sizing: border-box;
	box-shadow: none;
	margin: 0;

	//now independently define colors
	&.translucent {
		box-shadow: none;
		border-color: transparent;
		background-color: rgba(255, 255, 255, 0.1);
		color: #ffffff;

		&:focus {
			background-color: rgba(255, 255, 255, 0.05);
			color: #dddddd;
		}
		&::placeholder {
			color: #999999;
		}
		&:focus::placeholder {
			color: #aaaaaa;
		}
	}
}
.placeholder-search-icon {
	position: absolute;
	margin-left: 4px;
	color: #999999;
	pointer-events: none;
}
div.hide-archived {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: #ffffff;
	font-size: 14px;
	font-weight: 300;
	padding: 5px;
}
