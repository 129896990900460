.clearable-input {
	padding-right: 20px !important;
	box-sizing: border-box;
}

.input-x {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin-left: -22px;
	vertical-align: text-bottom;
}
