.RoleColorSelector {
	background-color: #777;
	//height: 260px;
	width: 196px;
	font-size: 80%;
	line-height: 1.6;
	position: absolute;
	right: 5px;
	border: 1px solid #999;
	box-shadow: 2px 2px rgba(0, 0, 0, 0.6);
	border-radius: 1px;

	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

.projectmemberlist .RoleColorSelector {
	left: 92px;
}

.RoleColorSelector ul {
	list-style-type: none;
	padding: 0;
	margin: 8px;
}

.RoleColorSelector ul li {
	display: inline-block;
	padding: 0;
	margin: 5px;
}

.RoleColorSelector ul li:hover {
	outline: 5px solid #212121;
}

ul li.used-color {
	outline: 3px solid #33a9fe;
}
ul li.selectedcolor {
	outline: 5px solid #288fdc;
}
